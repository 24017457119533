// 根据你的实际请求api.js位置路径修改
import "@/api/index";
// 根据你的实际添加编辑组件位置路径修改
import { shortcuts } from "@/libs/shortcuts";
import { router } from "@/router";
import { validateMobile, validatePassword } from "@/libs/validate";
export default {
  name: "companyInfo",
  components: {},
  data() {
    return {
      height: 600
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
    window.onresize = () => {
      return (() => {
        this.height = window.innerHeight;
      })();
    };
  }
};